import React from 'react';
import classNames from 'classnames';
import { ShoppingCart as ShoppingCartIcon, Trash as TrashIcon } from 'react-feather';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import PathMap from '~/shared/pathMap';
import styles from '../robot-bundle/styles.module.scss';
import summaryStyles from './styles.module.scss';
import { Price } from '../price';
import { LoadingSpinner } from '../loading-spinner';

type Props = {
  totalPrice: number;
  onClick?: () => void;
  onClear?: () => void;
  onClearState?: { isLoading?: boolean };
};

const RobotBundleCartSummary = ({ totalPrice, onClick, onClear, onClearState }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['robot-bundle__body']}>
      <div className={classNames(styles['price-info-block'], 'grid grid-cols-2')}>
        <div>
          <span className={classNames(styles['price-label'], 'font-bold')}>
            {t('myRobotBundlePriceLable')}
          </span>
          <span className={classNames(styles['price-hint'], 'block')}>
            {t('myRobotBundlePriceInfoLabel')}
          </span>
        </div>
        <div className={classNames(styles['price-block'])}>
          <span className={classNames(styles['price'], 'font-bold')}>
            <Price price={totalPrice} />
          </span>
        </div>
      </div>
      <Link
        id="myrobot-goto-cart"
        href={PathMap.quote.as()}
        onClick={onClick}
        role="button"
        tabIndex={-1}
        className={classNames(
          summaryStyles['robot-bundle-cart-summary__button'],
          'btn btn-lg btn-secondary btn-block'
        )}
      >
        <ShoppingCartIcon />
        <span>{t('myRobotBundleToCartBtn')} </span>
      </Link>
      {onClear && (
        <button
          className={classNames(
            summaryStyles['robot-bundle-cart-summary__resetButton'],
            'flex justify-center btn btn-lg btn-danger btn-block mt-4'
          )}
          onClick={onClear}
        >
          {onClearState?.isLoading ? (
            <LoadingSpinner className="h-[22px] w-[22px]" />
          ) : (
            <TrashIcon size={22} />
          )}
          <span>{t('myRobotBundleCleartBtn')}</span>
        </button>
      )}
    </div>
  );
};

export default RobotBundleCartSummary;
